.Login {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.LoginCard {
	padding: 1rem;
	width: 30vw;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),
							0 2px 2px 0 rgba(0,0,0,.14),
							0 1px 5px 0 rgba(0,0,0,.12);

}

@media (max-width: 640px) {
	.LoginCard {
		width: 90vw;
	}
}

.Login-input {
	display: flex;
	align-items: flex-end;
	width: 100%;
}
