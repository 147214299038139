.DetailCalendar {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-gap: 20px;
}

.Calendar-header {
	padding: .8rem;
	background-color: #48cdd2;
	color: #fff;
}
.Calendar-header-year {
	font-size: 14px;
}

Calendar-header-title {
	font-weight: 400;
}

.CalendarMonth-list {
	display: grid;
	grid-template-columns: repeat(7,1fr);
	list-style: none;
}

.CalendarMonth-item {
	padding: .5rem;
	color: #999;
	font-size: 14px;
}

.CalendarMonth-item.day {
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	transition: all .3s linear;
	cursor: pointer;
}
