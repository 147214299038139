body {
	margin: 0;
	padding: 0;
	font-family: 'Roboto', sans-serif;
	box-sizing: border-box;
	font-size: 16px;
	background-color: #f9f9f9;
}

*, *::after, *::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.none {
	display: none;
}

.fullWidth {
	width: 100%
}

.flex {
	display: flex;
}
.flex.around {
	justify-content: space-around;
}
.flex-wrap {
	flex-wrap: wrap;
}
.flex-center {
	justify-content: center;
}

.flex-center-y {
	align-items: center;
}
td {
	white-space: nowrap;
}
