select, input {
  width: 100%;
}

.Inscription {
	height: 100%;

}
.Inscription input {
  text-transform: uppercase;
}
.Inscription table {
  width: 100%;
}
.Inscription td > * {
  width: 100% !important;
}
.Inscription input {
  padding: 10px 5px;
}
.Inscription-contained {
  width: 95%;
  margin: 0 auto;
}
.Inscription-header {
  position: fixed;
  z-index: 1111;
  top: 0;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -2px 2px 10px 0px rgba(0, 0, 0, 0.3);
}
.Inscription-calazacon {
  text-transform: uppercase;
  font-size: 50px;
  margin-top: 30px;
  text-shadow: 0 1px 1.5px rgba(0, 0, 0, 0.3);
}

.table-title {
  text-transform: uppercase;
}

.requisitos-input {
  display: none;
}

.TableForm-title {
  margin-bottom: 15px;
}

.TableForm-title span {
  display: block;
  font-size: 15px;
}

.TableForm-footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.TableForm-card {
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px;
  box-shadow: -2px 2px 10px 0px rgba(0, 0, 0, 0.3);
}
